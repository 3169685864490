import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next"

export default function NotFound({ location }) {
  const { pathname } = location
  const { navigate } = useI18next()
  const { t } = useTranslation()
  if (pathname.includes("blog")) {
    console.log("Pathname is: " + pathname)
    //Go back to blog overview page if blog doesn't have a translation
    //NOTE: Shouldn't happen, since gatsby-plugin-react-i18next takes care in this care along with blog-details.js "if (!isDefaultLanguage) {" logic
    navigate("/blog/", { replace: true })
  }
  return (
    <Layout subTitle={t("Not Found")}>
      <section className="section is-medium has-text-centered">
        <h2 className="title is-2 is-spaced">
          <Trans>Not Found</Trans>
        </h2>
        <h3 className="subtitle is-4 is-spaced">
          <Trans>Sorry, that page doesn't exist!</Trans>
        </h3>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
